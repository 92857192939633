@import '../../styles/mixins.scss';

.make-picks-page {
	position: relative;
	width: 100vw;
	overflow-y: auto;
	height: 100vh;
	.make-picks-content {
		width: 100%;
		height: auto;
		display: flex;
		align-items: center;
		flex-direction: column;
		padding-bottom: 15%;
		.popup {
			width: 100vw;
			height: 100vh;
			position: absolute;
		}
		.instructions {
			width: 80%;
			position: relative;
			height: auto;
			margin-bottom: 2rem;
			padding: 1rem;
			height: 50%;
			margin-top: 2rem;
		}
		.games-container {
			@include flexCol();
			overflow-y: auto;
			position: relative;
			height: auto;
			width: 80%;
			justify-content: center;
			// hide the radio button icon
			input {
				display: none;
			}
			.games-box {
				width: 100%;
				height: auto;
				display: flex;
				font-family: 'Roboto', sans-serif;
				.game {
					width: 100%;
					@include alignCenter();
					flex-shrink: 0;
					padding: 1rem;
					justify-content: space-between;

					.box {
						height: 70px;
						width: 40%;
						@include alignCenter();
						box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
						border-radius: 5px;
						padding: 1rem;
						cursor: pointer;
						color: #fff;
						background-color: var(--lighter-primary);

						&:hover {
							box-shadow: none;
							transition: all 100ms ease-in-out;
						}
					}
					.highlight {
						background: #ffffaa;
						color: black;
					}
					.no-pick {
						background-color: #e2e2e4;
						&:hover {
							cursor: not-allowed;
						}
					}
					.logo {
						height: 100%;
						width: 20%;
						@include flexCenter();
						margin-right: 1rem;
						.team-logo {
							max-width: 50px;
						}
					}
					.abbr {
						display: none;
					}
					.team-id {
						height: 100%;
						width: 55%;
						p {
							font-size: 0.9rem;
						}
					}
				}
			}
			.btn-container {
				width: 100%;
				margin-top: 1rem;
				display: flex;
				justify-content: center;
				.add-btn {
					color: #fff;
					width: 25%;
					height: 50px;
					font-family: 'Roboto', sans-serif;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 1.5rem;
					background-color: var(--accent);
					border-radius: 5px;
					&:hover {
						background-color: #1a9b49;
					}
				}
			}
		}
	}
}

// LAPTOP SIZE
@include laptop {
	.make-picks-page {
	}
}

// MOBILE SIZE
// @include macbook {
// 	.make-picks-page {
// 		padding: 0rem 8rem;
// 		flex-direction: column;
// 		.instructions-section {
// 			width: 100%;
// 		}
// 		.games-container {
// 			width: 100%;
// 			height: 100%;
// 			.games-box {
// 				.game {
// 					justify-content: space-between;
// 					padding: 1rem 0;
// 				}
// 				.box {
// 					width: 50%;
// 					@include flexCenter();
// 				}
// 			}
// 		}
// 	}
// }

// PHONE SIZE
@include phone {
	.make-picks-page {
		flex-direction: column;
		.make-picks-content {
			width: 100%;
			.instructions {
				width: 100%;
			}
			.games-container {
				width: 100%;
				height: 100%;
				justify-content: flex-start;
				padding: 1rem;
				z-index: 9998;
				.btn-container {
					button {
						min-width: 100%;
					}
				}
				.games-box {
					.game {
						justify-content: space-between;
						padding: 1rem 0;
					}

					.box {
						width: 50%;
						justify-content: center;
						.record,
						.home-away {
							display: none;
						}
						.team-id {
							max-width: 40%;
							@include alignCenter();
						}
						.logo {
							min-width: 40%;
							img {
								min-width: 50px;
							}
						}
					}
					.no-pick {
						.team-id {
							display: none;
						}
						.abbr {
							display: block;
						}
					}
					.game-info {
						font-size: 0.7rem;
						text-align: center;
					}
				}
			}
		}
	}
}
