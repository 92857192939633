@import '../../styles/mixins.scss';

.pool {
	padding: 2rem 20rem;
	font-family: 'Roboto', sans-serif;
	background-color: var(--secondary-color);
	color: #fff;
	.no-user-msg {
		width: 100%;
		margin-top: 4rem;
		p {
			text-transform: uppercase;
			font-size: 2rem;
			text-align: center;
			color: var(--secondary-color);
		}
	}
	.label-container {
		margin-bottom: 1rem;
		display: flex;
		.labels {
			display: flex;
			width: 100%;
			justify-content: space-between;
			.name-label {
				width: 200px;
				@include alignCenter();
			}
			.record-label {
				width: 200px;
				@include flexCenter();
				display: flex;
				justify-content: flex-end;
			}
			.matchups {
				display: flex;
				width: 100%;
				.label-box {
					width: 100%;
					@include flexCol();
					@include alignCenter();
					p {
						margin: 0.1rem 0;
					}
					.score {
						font-size: 0.8rem;
						font-weight: bold;
					}
					.winner {
						color: var(--text-color);
					}
					.losing {
						color: var(--text-color);
					}
				}
			}
		}
	}
	.all-picks {
		@include flexCol();
		gap: 1rem;
		.user-pick-box {
			@include alignCenter();
			width: 100%;
			justify-content: space-between;
			height: 50px;
			gap: 1rem;
			.user {
				width: 200px;
				position: relative;
				display: flex;
				justify-content: center;
				.user-email {
					width: 100%;
					display: flex;
				}
				.verified {
					max-width: 20px;
					margin-left: 0.2rem;
				}
				.record {
					font-size: 1.5rem;
					@include flexCenter();
					width: 100%;
					display: flex;
					justify-content: flex-end;
				}
			}
			.user-pick {
				display: flex;
				width: 100%;
				align-items: center;
				gap: 0.5rem;
				.winner {
					background-color: var(--accent);
					width: 55px;
					@include flexCol();
					@include flexCenter();
					width: 100%;
					box-shadow: var(--box-shadow);
					border-radius: 4px;
					img {
						width: 45px;
					}
				}
				.loser {
					background-color: #f02323;
					width: 55px;
					@include flexCol();
					@include flexCenter();
					width: 100%;
					box-shadow: var(--box-shadow);
					border-radius: 4px;
					img {
						width: 45px;
					}
				}
				.picked-logo {
					width: 100%;
					@include alignCenter();

					position: relative;
					justify-content: center;
					img {
						width: 45px;
					}
				}
			}
		}
	}
}

// LAPTOP SIZE
@include laptop {
	.pool {
		padding: 8rem;
	}
}

// PHONE SIZE
@include phone {
	.pool {
		padding: 2rem;
		overflow-x: auto;
		.all-picks,
		.label-container {
			width: 1000px;
		}
	}
}
