.picks-page {
	height: 100vh;
	width: 100vw;
	position: relative;
	overflow: hidden;
	background-color: var(--secondary-color);
	color: #fff;
	.content {
		width: 100vw;
		height: 100vh;
		position: relative;
		.no-user-msg {
			width: 100%;
			margin-top: 4rem;
			font-family: 'Roboto';
			p {
				text-transform: uppercase;
				font-size: 2rem;
				text-align: center;
				color: #fff;
			}
		}
	}
}
