@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.show-picks {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20rem;
  .info-text {
    height: 30%;
    h1 {
      font-size: 3rem;
      font-family: "Oswald", sans-serif;
      margin-bottom: 1rem;
    }
    p {
      font-size: 1.4rem;
      font-family: "Roboto", sans-serif;
      margin-bottom: 1rem;
    }
  }
  .labels {
    display: flex;
    padding: 0 1rem;
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif;

    .l-entry {
      width: 20%;
    }
    .l-picks {
      width: 60%;
      text-align: center;
    }
    .l-delete {
      width: 20%;
      margin-left: auto;
      width: 100px;
      text-align: center;
    }
  }
  .picks-container {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #f7f8fa;
    border-radius: 10px;
    padding: 1rem 1rem;
    margin-top: 1rem;
    font-family: "Roboto", sans-serif;

    .entry-status {
      width: 20%;
    }
    .picks {
      display: flex;
      width: 60%;
      justify-content: center;
      .pick {
        display: flex;
        align-items: center;
        justify-content: center;
        .logo {
          width: 45px;
          margin: 0 0.3rem;
        }
      }
    }
    .delete-picks {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .delete-btn {
        background-color: var(--danger-color);
        padding: 10px 30px;
        border: none;
        width: 100px;
        border-radius: 4px;
        color: white;
        font-size: 1rem;
        font-weight: lighter;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: var(--box-shadow);
        &:hover {
          box-shadow: none;
          transition: all 100ms ease-in-out;
        }
      }
    }
  }
  .text {
    margin-top: 2rem;
    h1 {
      font-family: "Oswald", sans-serif;
      font-size: 2.3rem;
      padding-bottom: 1rem;
    }
    .news-container {
      display: flex;
      gap: 1rem;
      overflow-y: hidden;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .news-box {
        width: 350px;
        height: 250px;
        position: relative;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        border: 1px solid lightgray;
        .img-wrapper {
          overflow-y: hidden;
          height: 121px;
          position: relative;
          img {
            max-width: 250px;
          }
        }
        .news-text {
          .headline {
            font-family: "Roboto", sans-serif;
            padding: 1rem 0.5rem 0 0.5rem;
          }
          a {
            font-size: 0.8rem;
            font-family: "Roboto", sans-serif;
            
            position: absolute;
            bottom: 0.25rem;
            left: 0.5rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  footer {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    font-size: 0.7rem;
    font-family: "Roboto", sans-serif;
    text-align: center;
  }
}

// LAPTOP SIZE
@include laptop {
  .show-picks {
    padding: 0rem 8rem;
  }
}


// PHONE SIZE
@include phone {
  .show-picks {
    padding: 0rem 2rem;
    .labels {
      display: none;
    }
    .picks-container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 1rem 1rem;

      .entry-status {
        width: 100%;
      }
      .picks {
        width: 100%;
        margin: 1rem 0 ;
        overflow-x: scroll;
        justify-content: flex-start;
        img {
          max-width: 40px;
        }
      }
      .delete-picks {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        button {
          margin-right: auto;          
        }
      }
    }
  }
}
