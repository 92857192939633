@import '../../styles/mixins.scss';

.register-page,
.login-page {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	position: fixed;
	max-width: 100%;

	.register-overlay {
		background-color: #000;
		position: absolute;
		width: 100vw;
		height: 100vh;
		left: 0;
		top: 0;
		opacity: 0.8;
		max-width: 100%;
	}
}

// PHONE SIZE
@include phone {
	.register-page,
	.login-page {
		width: 100%;
		height: 100vh;
		display: flex;
	}
}
