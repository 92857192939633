@mixin btn() {
	padding: 0.5rem;
	border-radius: 4px;
	background-color: black;
	color: white;
	padding: 10px 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: var(--secondaryFont);
	font-weight: lighter;
	&:hover {
		box-shadow: var(--box-shadow);
	}
}

@mixin link() {
	color: var(--link-color);
}

@mixin flexCol() {
	display: flex;
	flex-direction: column;
}

@mixin flexCenter() {
	display: flex;
	align-items: center;
	justify-content: center;
}

@mixin alignCenter() {
	display: flex;
	align-items: center;
}

@mixin card() {
	border-radius: 4px;
}

@mixin phone {
	@media only screen and (max-width: 856px) {
		@content;
	}
}

@mixin laptop {
	@media only screen and (max-width: 1600px) {
		@content;
	}
}

@mixin macbook {
	@media only screen and (max-width: 1280px) {
		@content;
	}
}
