@import '../../styles/mixins.scss';

.home-nav {
	width: 100vw;
	@include alignCenter();
	font-family: var(--secondaryFont);
	position: relative;
	height: 65px;
	background-color: var(--lighter-primary);
	color: var(--text-light);
	font-family: 'Roboto';
	padding: var(--padding);
	max-width: 100%;

	.team-dropdown,
	.hide {
		background-color: #fff;
		position: absolute;
		top: 30px;
		z-index: 9999;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		opacity: 1;
		transition: all 300ms ease-in-out;
		padding: 1rem;
		border-bottom-right-radius: 6px;
		border-bottom-left-radius: 6px;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
		.option {
			display: flex;
			gap: 1rem;
			align-items: center;
			color: black;
			font-size: 1rem;
			padding: 0.5rem 0;
			width: 270px;
			text-decoration: none;
			cursor: pointer;
			img {
				max-width: 40px;
			}
			&:hover {
				background-color: rgb(239, 239, 239);
				cursor: pointer;
			}
		}
	}

	.hide {
		opacity: 0;
		transition-delay: 1s, 250ms;
		transition: all 360ms ease-in-out;
		z-index: -100;
		visibility: hidden;
	}
	.user-sub-nav {
		position: absolute;
		padding: var(--padding);
		top: 6rem;
	}
	.hide {
		display: none;
	}
	.desktop-links {
		display: block;
	}
	.brand,
	.user-info {
		width: auto;
		height: 100%;
		display: flex;
		gap: 1rem;
		align-items: center;
		font-size: 1rem;
		a {
			width: 160px;
			font-size: 1.4rem;
		}
		.register,
		.login {
			background-color: var(--accent);
			color: var(--text-color);
			padding: 4px 20px;
			border-radius: 5px;
			height: 35px;
			display: flex;
			align-items: center;
			cursor: pointer;
		}
		.register {
			&:hover {
				background-color: #1a9b49;
			}
		}
		.login {
			background-color: var(--secondary-color);
			&:hover {
				background-color: #127ddb;
			}
		}
	}
	.mobile-user {
		margin-left: auto;
		padding-right: 1rem;
		font-size: 1.4rem;
		display: flex;
		justify-content: center;
		align-items: center;
		display: none;
	}
	.dropdown {
		width: 100vw;
		transition: all 100ms ease-in-out;
		position: relative;
		display: none;
		left: 0;
		top: 0;
		background-color: #fff;
		z-index: 9999;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;

		.mobile-links {
			ul {
				@include flexCol();
				font-size: 1rem;
				justify-content: center;
				height: 100%;
				a {
					height: 60px;
					@include alignCenter();
					font-weight: lighter;
					padding-left: 2rem;
					&:hover {
						background-color: #f7f8fa;
					}
				}
				.last {
					border-bottom: 1px solid lightgray;
				}
			}
		}
	}
	.hide {
		display: none;
	}
	.nav-links,
	.desktop-links {
		width: 100%;
		height: 100%;
		justify-content: flex-start;
		position: relative;
		li {
			height: 100%;
			display: flex;
			align-items: center;
			font-weight: 300;
		}
		ul {
			display: flex;
			gap: 1rem;
			height: 100%;
			align-items: center;
			position: relative;
			font-weight: 300;
		}
	}
	.user-info {
		@include alignCenter();
		justify-content: flex-end;
		.mobile-user {
			display: none;
		}
		.down-arrow {
			margin-left: 0.5rem;
		}
		p {
			background-color: var(--accent);
			padding: 0.5rem 0.75rem;
			color: white;
			text-transform: uppercase;
			border-radius: 6px;
			font-size: 0.9rem;
			margin-left: auto;
			width: auto;
			display: flex;
			height: 40px;
			align-items: center;
			cursor: pointer;
			&:hover {
				background-color: #1a9b49;
			}
		}
		.burger {
			font-size: 1.5rem;
			margin-left: auto;
			cursor: pointer;
			margin-right: 2rem;
		}
		.mobile-burger {
			display: none;
		}
	}
}

.sub-nav {
	width: 100%;
	height: 50px;
	background-color: #002244;
	padding: var(--padding);
	display: flex;
	align-items: center;
	display: none;
}

// LAPTOP SIZE
@include laptop {
}

// PHONE SIZE
@include phone {
	.home-nav {
		padding: 2rem 1rem;
		position: relative;
		align-items: center;
		.user-sub-nav {
			width: 100%;
			right: 0;
			padding: 1rem 2rem;
		}
		.brand {
			width: 45%;
			margin-left: 1rem;
		}
		.mobile-user {
			display: block;
		}
		.desktop-links {
			display: none;
		}
		.nav-links {
			display: block;
		}
		.dropdown {
			width: 100vw;
			transition: all 100ms ease-in-out;
			position: relative;
			background-color: var(--lighter-primary);
			position: absolute;
			top: 65px;
			z-index: 9999;
			display: block;
			box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;

			.mobile-links {
				display: block;
				padding: 0;
				color: #fff;
				ul {
					@include flexCol();
					font-size: 1rem;
					height: 100%;
					gap: 0;
					p,
					a {
						height: 60px;
						display: flex;
						align-items: center;
						font-weight: lighter;
						padding-left: 2rem;
						border-top: 1px solid var(--secondary-color);
						&:hover {
							background-color: var(--secondary-color);
						}
					}

					p {
						&:nth-last-child(1) {
							background-color: var(--accent);
						}
					}

					.last {
						border-bottom: 1px solid var(--secondary-color);
					}
				}
			}
		}
		.user-info {
			margin-left: auto;
			width: 50%;
			justify-content: center;
			margin-right: 1rem;
			height: 100%;
			display: none;
			.mobile-burger {
				display: block;
				font-size: 1.4rem;
				@include flexCenter();
			}
			.mobile-user {
				display: block;
				color: red;
			}
			.desktop-user {
				display: none;
			}
			p {
				font-size: 0.8rem;
				margin-left: auto;
			}

			.register {
				background-color: var(--secondary-color);
				color: white;
				padding: 4px 15px;
				border-radius: 7px;
				font-weight: lighter;
				&:hover {
					background-color: white;
					color: var(--secondary-color);
					outline: 1px solid var(--secondary-color);
					transition: all 200ms ease-in-out;
					font-weight: bold;
				}
			}
		}
	}
}
