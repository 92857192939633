@import '../../styles/mixins.scss';

.instructions-content {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	.content-date {
		font-size: 2rem;
		font-family: 'Oswald', sans-serif;
		width: 50%;
		display: flex;
		align-items: center;
		margin-bottom: 2rem;
	}
	.instructions-text {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		font-family: 'Roboto';
		width: 50%;

		p {
			font-size: 1.2rem;
			text-align: left;
		}
	}
}

// // LAPTOP SIZE
// @include laptop {
// 	.content {
// 		.content-date {
// 			font-size: 2.2rem;
// 			span {
// 				font-size: 3.2rem;
// 			}
// 		}
// 		.instructions {
// 			width: 90%;
// 			p {
// 				font-size: 1.2rem;
// 			}
// 		}
// 	}
// }

// PHONE SIZE
@include phone {
	.instructions-content {
		.content-date {
			font-size: 2rem;
			width: 100%;
			span {
				font-size: 3.2rem;
			}
		}
		.instructions-text {
			width: 100%;
			p {
				font-size: 1rem;
				font-family: 'Roboto', sans-serif;
			}
		}
	}
}
