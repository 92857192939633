@import '../../styles/mixins.scss';

.home-page {
	width: 100%;
	height: 100vh;
	font-family: 'Roboto';
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: var(--primary-color);
	color: var(--text-light);

	span {
		color: var(--secondary-color);
		text-decoration: underline;
	}
	.home-content {
		display: flex;
		height: calc(50vh - 65px);
		padding: var(--padding);
		background-color: var(--secondary-color);
		.mobile {
			display: none;
		}
	}
	.left {
		width: 50%;
		height: 100%;
		position: relative;
		.title {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			width: 100%;
			.home-text {
				position: relative;
				width: 100%;
				h1 {
					font-size: 3.4rem;
					text-transform: uppercase;
					line-height: 4.5rem;
					margin-bottom: 0.5rem;

					font-family: var(--primaryFont);
				}
				p {
					font-size: 1.4rem;
					font-weight: lighter;
					font-family: var(--secondaryFont);
				}
			}
			.btns {
				display: flex;
				gap: 3rem;
				margin-top: 2rem;
				align-items: center;

				.register-btn {
					background-color: var(--lighter-primary);
					color: white;
					padding: 10px 20px;
					border-radius: 7px;
					font-weight: lighter;
					transition: all 200ms ease-in-out;
					font-size: 1rem;
					cursor: pointer;
				}
			}
		}
	}

	.right {
		width: 50%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		gap: 1.4rem;

		.first {
			margin-right: auto;
		}
		.second {
			margin-left: auto;
		}
		.demo-bar {
			display: flex;
			width: 100%;
			justify-content: space-between;
			background-color: var(--lighter-primary);
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			border-radius: 8px;
			position: relative;
			height: 65px;
			color: var(--text-light);
			.demo-bar-content {
				width: 100%;
				display: flex;
				position: absolute;
				flex-shrink: 0;
				height: 100%;
				background-color: var(--lighter-primary);
			}
			.vegas-odds {
				width: 60%;
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				font-size: 1.3rem;
			}
			.home,
			.away {
				width: 45%;
				display: flex;
				align-items: center;
				justify-content: center;
				img {
					max-width: 50px;
				}
			}
		}
	}
}

.video-container-home {
	font-family: 'Roboto';
	padding: var(--padding);
	display: flex;
	align-items: center;
	margin: 0 auto;
	width: 100%;
	position: relative;
	height: 50vh;
	gap: 1rem;
	flex-direction: column;
	background-color: var(--secondary-color);
	.carousel {
		width: 100%;
		height: 100%;
		position: relative;

		.video-box {
			max-width: 400px;
			height: auto;
			position: relative;
			display: flex;
			flex-direction: column;
			margin: 0 1rem;
			video {
				max-width: 100%;
				box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			}
			.video-text {
				height: 115px;
				.headline {
					padding: 0.25rem 0.5rem;

					font-weight: bold;
				}
				.description {
					padding: 0.6rem 0.5rem;
					border-bottom-right-radius: 4px;
					border-bottom-left-radius: 4px;
					padding-bottom: 0.25rem;
				}
			}
		}
	}
	h1 {
		text-align: left;
		width: 100%;
	}
	.videos {
		width: 100%;
		display: flex;
		gap: 2rem;
		overflow-x: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
	}
}

// LAPTOP SIZE
@include laptop {
	.home-page {
		// padding: 0 8rem;

		.guest-login {
			margin-top: 4rem;
		}
		.title {
			width: 90%;
			.home-text {
				max-width: 95%;
				h1 {
					font-size: 3.3rem;
					line-height: 3.3rem;
				}
				p {
					font-size: 1.8rem;
					line-height: 1.8rem;
				}
			}
			.btn-container-home {
				width: 100%;
				@include flexCol();
				gap: 1rem;
				.btn {
					width: 300px;
					@include flexCenter();
					margin-top: 1rem;
				}
			}
		}
	}
	.video-container-home {
	}
}

// PHONE SIZE
@include phone {
	.home-page {
		width: 100%;
		padding: 0;
		@include flexCol();
		height: 100%;
		.home-content {
			padding-top: 1rem;
			height: auto;
			display: flex;
			flex-direction: column;
		}
		.left {
			width: 100%;
			.title {
				padding: 1rem;
				text-align: center;
				width: 100;
				.home-text {
					width: 100%;
					h1 {
						font-size: 2.8rem;
						line-height: 3.3rem;
						margin-bottom: 1rem;
					}
					p {
						font-size: 1.2rem;
						line-height: 1.6rem;
						font-family: var(--secondaryFont);
					}
				}
				.btns {
					display: flex;
					justify-content: center;
					flex-direction: column;
					gap: 0.5rem;
					.register-btn {
						background-color: var(--lighter-primary);
						color: white;
						border-radius: 7px;
						font-weight: lighter;
					}
					.mobile {
						display: flex;
					}
				}
			}
		}
		.right {
			width: 100%;
			.first,
			.second {
				margin: 0 auto;
				width: 100%;
			}
		}
	}
	.video-container-home {
		height: 100%;
		width: 100%;
		padding: 1rem;
		padding-top: 2rem;

		.highlights-title {
			text-align: center;
		}
		.videos {
			flex-direction: column;
			margin-top: 1rem;
			width: 100%;
			height: auto;
			align-items: center;
			.video-box {
				max-width: 300px;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;

				.headline {
					width: 100%;
					margin-bottom: 0.5rem;
				}
				video {
					margin-bottom: 0.5rem;
				}
			}
		}
	}
}
