:root {
	--primary-color: #f0f3f8;
	--secondary-color: #1493ff;
	--lighter-primary: #1f375b;
	--accent: #1aac50;
	--box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.03);
	--text-dark: #1381e0;
	--text-light: #ffffff;
	--danger-color: #ef4056;
	--black: #1e1e1e;
	--primaryFont: 'Oswald', sans-serif;
	--secondaryFont: 'Roboto', sans-serif;
	--padding: 0rem 20rem;
	--white: #fff;
}

@media (max-width: 1700px) {
	:root {
		--padding: 0rem 10rem;
	}
}

@media (max-width: 1400px) {
	:root {
		--padding: 0rem 7rem;
	}
}

@media (max-width: 1140px) {
	:root {
		--padding: 0rem 4rem;
	}
}

@media (max-width: 970px) {
	:root {
		--padding: 0rem 2rem;
	}
}
